import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Countries from 'models/countries';
import { puntoSwitcher } from 'utils/puntoSwitcher';
import CountryCard from 'components/widgets/country-card';

const CountriesModel = {
  display: [],
  value: '',
  filter({ target: { value: newValue } }) {
    const value = puntoSwitcher(newValue);
    let display = Countries.list.filter(country =>
      country.name.startsWith(value),
    );

    if (!display.length) {
      display = Countries.list.filter(item =>
        item.subname.some(submane => submane.startsWith(value)),
      );
    }

    CountriesModel.value = value;
    CountriesModel.display = display;
  },
};

export default {
  oninit() {
    document.title = 'Страны — Visard';
    this.template = resolveTemplate('pages/countryList');
    CountriesModel.display = [...Countries.list];
  },
  view() {
    return this.template({
      state: {
        filter: CountriesModel.value,
      },
      children: {
        countries: CountriesModel.display.map(country =>
          m(CountryCard, { country }),
        ),
      },
      actions: {
        onFilter: CountriesModel.filter,
      },
    });
  },
};
