const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div
  div.gridCountryFilter
    h1.nameBig.colorGray Все страны для оформления визы
    label.gridCountryFilter_input.inputStandart.inputStandart--placeholder.inputStandart--width100
        input.inputStandart_input(
          placeholder='Найти'
          oninput=actions.onFilter
          value=state.filter
          required
        )
        //- span.inputStandart_label Найти
        div.inputStandart_line
  div.gridCountry
    div.gridCountry_list
      = children.countries

  `;
};