/* eslint-disable */
import m from 'mithril';
import c from 'classnames';

import resolveTemplate from 'utils/resolveTemplate';
import Countries from 'models/countries';
import LoadingBar from 'models/loadingBar';

import { openModal } from 'components/layout/modal';

// #if process.env.IS_MOBILE == 'true'
const { Link } = m.route;
const panel = {
  view() {
    return pug`
    .orderCalculator_issueVisaFormFixedWrap
      Link.buttonStandart(
        href='/'
      )
       span.buttonStandart_name Начать оформление
    `;
  },
};
// #endif

// #if process.env.IS_MOBILE == 'false'
// // import panel from './home/order/panel';
// #endif

let needClass = false;

// #if process.env.IS_MOBILE == 'false'
// // const onScroll = () => {
// //   if (window.scrollY >= 52) {
// //     if (!needClass) {
// //       needClass = true;
// //       m.redraw();
// //     }
// //   } else {
// //     if (needClass) {
// //       needClass = false;
// //       m.redraw();
// //     }
// //   }
// // };
// #endif

export default {
  oninit() {
    this.id = Countries.current.id;
    const idString = this.id < 10 ? `0${this.id}` : `${this.id}`;
    LoadingBar.finish();

    // #if process.env.IS_MOBILE == 'false'
// //     window.addEventListener('scroll', onScroll);
    // #endif

    this.bg = Countries.current.background.blur;
    this.mincost = Countries.current.mincost;
  },
  // #if process.env.IS_MOBILE == 'false'
// //   onremove() {
// //     window.removeEventListener('scroll', onScroll);
// //   },
  // #endif
  view() {
    return m.fragment({}, [
      m(
        'div.backBlur',
        m('img.backBlur_img', {
          src: this.bg,
        }),
      ),
      m(
        '.countyPage',
        {
          'data-countryid': this.id,
          'data-pricefrom': this.mincost,
        },
        [
          m.trust(Countries.html[this.id]),
          m(panel, {
            className: c(
              ['countyPage_side--right', 'orderCalculator--vertical'],
              {
                'orderCalculator--sticked': needClass,
              },
            ),
          }),
        ],
      ),
    ]);
  },
};
